<template>
  <div>
    <p>loading...</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.code;
    let jumpUrl = "https://app.adjust.com/" + type;
    console.log(jumpUrl);
    window.location.href = jumpUrl;
  },
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>
